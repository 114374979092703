import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Api, Http } from 'src/api';
import { authQueries, useProfileQuery } from 'src/api/queries';
import { AuthLoginRequest, AuthLoginResponse } from 'src/api/services/AuthClient';
import { Broadcast } from 'src/broadcast';
import { pushNotification } from 'src/lib/services/push-notification';
import { joinExistingValues } from 'src/helpers/joinExistingValues';
import { JsonWrapper } from '../JsonWrapper';
import { AuthJwtObject, jwtStorageKey } from './Auth.types';
import { AuthUtils } from './Auth.utils';
import { isAuthJwtObject } from './Auth.guards';
import { useUnsetVisitedPageCookie } from 'src/helpers/useUnsetVisitedPageCookie';
import { cookieKey } from 'src/types/global';
import { appRoutes } from 'src/routes/routes.types';
import { useRedirectMiddleware } from 'src/routes/RedirectMiddleware';
import { useAuthStore } from './Auth.store';
import { useDecoratedPostHog } from 'src/providers/posthog';
import { useCanny } from 'src/lib/services/canny';
import { useBreakpoint } from 'src/lib/hooks';
import { hubspot } from 'src/lib/services/hubspot';

let timeoutId: NodeJS.Timeout | null = null;

const useAuth = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const redirectMiddleware = useRedirectMiddleware();
  const { setIsLoggedIn, setAppStarted } = useAuthStore();
  const postHog = useDecoratedPostHog();
  const { isMobile } = useBreakpoint();

  const removeVisited = useUnsetVisitedPageCookie();

  const query = useProfileQuery({ enabled: false });
  const canny = useCanny();

  const isLoaded = query.isSuccess || query.isError,
    isLoading = query.isLoading,
    isInitialLoading = query.isInitialLoading;

  const startSession = async (jwt: string | AuthJwtObject) => {
    const token = isAuthJwtObject(jwt) ? jwt : AuthUtils.make(jwt);

    AuthUtils.setJwt(token);
    Http.setBearer(token.token);
    if (document.visibilityState !== 'hidden') {
      Broadcast.user.connect(token.token);
    }
    if (pushNotification.isSupported()) {
      pushNotification.init();
    }

    const data = await queryClient.fetchQuery({ queryKey: query.queryKey, queryFn: query.queryFn });

    const fullName = joinExistingValues([data.first_name, data.last_name], ' ');

    postHog.identify(data.id, {
      email: data.email,
      company_id: data.company.id,
      company_has_brands: data.company.meta.has_brands,
      company_has_requests: data.company.meta.has_requests,
      company_submitted_requests: data.company.meta.submitted_requests,
      company_has_pro_plan: data.company.meta.has_pro_plan,
      company_has_subscriptions: data.company.meta.has_subscriptions,
    });
    postHog.group('company', data.company.id, {
      name: data.company.name,
    });

    canny.identify({
      id: data.id,
      name: fullName.substring(0, 50), // canny fails to identify if name is longer than 50 characters
      email: data.email,
      created: new Date(data.created_at).toISOString(),
      customFields: {
        userType: data.type,
      },
      companies: [
        {
          id: data.company.id,
          name: data.company.name,
          monthlySpend: data.company.monthly_spend,
        },
      ],
    });

    hubspot.identify(data.email);
    hubspot.trackPageView();

    setIsLoggedIn(true);
  };

  const endSession = async () => {
    queryClient.cancelQueries();
    queryClient.removeQueries();

    Http.setBearer('');
    Broadcast.user.disconnect();
    postHog.reset();

    Object.values(cookieKey).forEach((key) => removeVisited(key));

    AuthUtils.removeJwt();

    setIsLoggedIn(false);
  };

  const login = async (data: AuthLoginRequest): Promise<AuthLoginResponse> => {
    const { access_token } = await Api.auth.login(data);

    await startSession(access_token);

    return { access_token };
  };

  const logout = async (): Promise<void> => {
    await Api.auth.logout();

    await endSession();
  };

  const refresh = async () => {
    if (AuthUtils.isExpired()) {
      AuthUtils.removeJwt();

      setAppStarted(true);
      return;
    }

    await Api.auth
      .refresh()
      .then(async ({ access_token }) => {
        if (!access_token) {
          await endSession();

          return;
        }
        await startSession(access_token);
      })
      .catch(async () => await endSession())
      .finally(() => setAppStarted(true));
  };

  const handleStorageTokenChange = async (e: StorageEvent): Promise<void> => {
    if (e.key !== jwtStorageKey) {
      return;
    }

    if (e.oldValue && !e.newValue) {
      // Means user has logged out
      await endSession();
      navigate(appRoutes.login);
      return;
    }

    const newJwt = JsonWrapper.toJson(e.newValue);
    if (!isAuthJwtObject(newJwt)) {
      return;
    }

    if (!e.oldValue) {
      // Means user has logged in
      await startSession(newJwt);
      await redirectMiddleware(appRoutes.dashboard);
      return;
    }

    if (e.oldValue && e.newValue) {
      // Means token has been refreshed
      const oldJwt = JsonWrapper.toJson(e.oldValue);

      if (
        isAuthJwtObject(oldJwt) &&
        !AuthUtils.isExpired(newJwt) &&
        oldJwt.token !== newJwt.token
      ) {
        await startSession(newJwt);
      }
    }
  };

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'hidden') {
      timeoutId = setTimeout(() => {
        Broadcast.user.disconnect();
      }, 5000);

      return;
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (!Broadcast.user.isConnected() && !AuthUtils.isExpired()) {
      Broadcast.user.connect(AuthUtils.getJwt().token);
      queryClient.invalidateQueries({
        queryKey: [authQueries.notifications],
      });
      queryClient.invalidateQueries({
        queryKey: [authQueries.unreadNotifications],
      });
      queryClient.invalidateQueries({
        queryKey: [authQueries.requestCounts],
      });
    }
  };

  const mount = () => {
    window.addEventListener('storage', handleStorageTokenChange);
    window.addEventListener('visibilitychange', handleVisibilityChange);
  };

  const dismount = () => {
    window.removeEventListener('storage', handleStorageTokenChange);
    window.removeEventListener('visibilitychange', handleVisibilityChange);
  };

  return {
    isLoaded,
    isLoading,
    isInitialLoading,
    login,
    logout,
    refresh,
    startSession,
    endSession,
    mount,
    dismount,
  };
};

export { useAuth };
